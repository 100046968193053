// import epsLogin from './components/Login.vue'
// import epsHome from './components/dashboard/Home.vue';
//Experiments
const FileUpload = () =>
    import('./TryNError/FileUpload');
const shivam = () =>
    import('./TryNError/shivam');
const Sample = () =>
    import('./TryNError/sample');
const SampleCalendar = () =>
    import('./TryNError/SampleCalendar');
const TT = () =>
    import('./TryNError/TT');
//ends
const ForgotPasswordcopy = () =>
    import('./components/ForgotPasswordcopy.vue');
const ForgotPassword = () =>
    import('./components/ForgotPassword.vue');
const CompanyOfferingRoundLearnerStatus = () =>
    import('./components/AdminMaster/CompanyOfferingRoundLearnerStatus');
const ChangePassword = () =>
    import('./components/ChangePassword.vue');
const epsLogin = () =>
    import('./components/Login.vue');
const epsLogincopy = () =>
    import('./components/Logincopy.vue');
const AluminSignupcopy = () =>
    import('./components/AluminSignupcopy.vue');
const alumin_signup = () =>
    import('./components/alumin_signup.vue');
const epsHome = () =>
    import('./components/dashboard/homenew.vue');
const Erp_check = () =>
    import('./components/dashboard/Erp_check.vue');
const EmployerFeedback = () =>
    import('./components/FeedBack/EmployerFeedback.vue');
//ends

// ----------------Profile ---------------------
const userProfile = () =>
    import('./components/profile/UserProfile');
const updatePassword = () =>
    import('./components/profile/UpdatePassword');
//................/.Profile-------------------

//----------------- Admin --------------------
const SignIn = () =>
    import('./components/Admin/Login/SignIn');
import RoleLink from './components/RoleLink.vue' //vrushali

//Admin ends

//TPO_SuperMaster.....Onkar
//const AdminSuper = () => import ('./components/Admin/SuperMaster/AdminSuper');
//const SuperMaster = () => import ('./components/SuperAdmin/SuperMaster');
const AddTenant = () =>
    import('./components/Admin/AddTenant');
const AddOrganization = () =>
    import('./components/Admin/AddOrganization');
const AdminLinkSuperMaster = () =>
    import('./components/Admin/AdminLinkSuperMaster');
const AdminJobAPI = () =>
    import('./components/Admin/AdminJobAPI');
const AdminCrawlerSite = () =>
    import('./components/Admin/AdminCrawlerSite');
const AdminAddUser = () =>
    import('./components/Admin/AdminAddUser');
const PushMastersToTenant = () =>
    import('./components/Admin/PushMastersToTenant');
const AddRoleLink = () =>
    import('./components/Admin/SuperMaster/AddRoleLink');
const SuperAdminHome = () =>
    import('./components/Admin/SuperAdminHome');
const LoginSuperAdmin = () =>
    import('./components/Admin/LoginSuperAdmin');
const SuperMaster = () =>
    import('./components/Admin/SuperMaster/SuperMaster')
const FeedbackReport = () =>
    import('./components/Admin/FeedbackReport')
const FeedbackReportDetails = () =>
    import('./components/Admin/FeedbackReportDetails')

//Admin SuperMaster Onkar...
const AdminAcademicYearMaster = () =>
    import('./components/Admin/SuperMaster/AdminAcademicYearMaster');
const AdminCalendarYearMaster = () =>
    import('./components/AdminMaster/CalendarYear');
const AdminUserTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminUserTypeMaster');
const AdminRoleMaster = () =>
    import('./components/Admin/SuperMaster/AdminRoleMaster');
const AdminIndustryMaster = () =>
    import('./components/Admin/SuperMaster/AdminIndustryMaster');
const AdminCityMaster = () =>
    import('./components/Admin/SuperMaster/AdminCityMaster');
const AdminGenderMaster = () =>
    import('./components/Admin/SuperMaster/AdminGenderMaster');
const AdminBloodGroupMaster = () =>
    import('./components/Admin/SuperMaster/AdminBloodGroupMaster');
const AdminLanguageMaster = () =>
    import('./components/Admin/SuperMaster/AdminLanguageMaster');
const AdminAddressTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminAddressTypeMaster');
const AdminSkillCategoryMaster = () =>
    import('./components/Admin/SuperMaster/AdminSkillCategoryMaster');
const AdminSkillMaster = () =>
    import('./components/Admin/SuperMaster/AdminSkillMaster');
const AdminSocialMediaTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminSocialMediaTypeMaster');
const AdminDegreeMaster = () =>
    import('./components/Admin/SuperMaster/AdminDegreeMaster');
const AdminClassGradeMaster = () =>
    import('./components/Admin/SuperMaster/AdminClassGradeMaster');
const AdminPlacementStatusMaster = () =>
    import('./components/Admin/SuperMaster/AdminPlacementStatusMaster');
const AdminEmployabilityTestTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminEmployabilityTestTypeMaster');
const AdminPublicationLevelMaster = () =>
    import('./components/Admin/SuperMaster/AdminPublicationLevelMaster');
const AdminPublicationCategoryMaster = () =>
    import('./components/Admin/SuperMaster/AdminPublicationCategoryMaster');
const AdminRecruitmentTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminRecruitmentTypeMaster');
const AdminPlacementTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminPlacementTypeMaster');
const AdminCompanyTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminCompanyTypeMaster');
const AdminQuestionTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminQuestionTypeMaster');
const AdminQuestionStatusMaster = () =>
    import('./components/Admin/SuperMaster/AdminQuestionStatusMaster');
const AdminActivityTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminActivityTypeMaster');
const AdminTemplateTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminTemplateTypeMaster');
const AdminTemplateMaster = () =>
    import('./components/Admin/SuperMaster/AdminTemplateMaster');
const AdminFieldTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminFieldTypeMaster');
const AdminFieldMaster = () =>
    import('./components/Admin/SuperMaster/AdminFieldMaster');
const AdminProjectCategoryMaster = () =>
    import('./components/Admin/SuperMaster/AdminProjectCategoryMaster');
const AdminProgramTypeMaster = () =>
    import('./components/Admin/SuperMaster/AdminProgramTypeMaster');
const AdminCountryMaster = () =>
    import('./components/Admin/SuperMaster/AdminCountryMaster');
const AdminCompanyRoundMaster = () =>
    import('./components/Admin/SuperMaster/AdminCompanyRoundMaster');
const AdminCommunicationModeMaster = () =>
    import('./components/Admin/SuperMaster/AdminCommunicationModeMaster');

//End of Admin SuperMaster...

//------------------------------------- added by vrushali admin Master links -------------------------------------
const AdminDashboard = () =>
    import('./components/Admin/AdminDashboard');
const Master = () =>
    import('./TryNError/Master');
const home = () =>
    import('./components/AdminMaster/home');
const CVType = () =>
    import('./components/AdminMaster/LearnerCVType')
const CVTemplate = () =>
    import('./components/AdminMaster/LearnerCVTemplate')
const AcademicYear = () =>
    import('./components/AdminMaster/AcademicYear');
const Semester = () =>
    import('./components/AdminMaster/Semester');
const Setting = () =>
    import('./components/AdminMaster/Setting');
const Industry = () =>
    import('./components/AdminMaster/Industry');
const City = () =>
    import('./components/AdminMaster/City');
const AddressType = () =>
    import('./components/AdminMaster/AddressType');
const Gender = () =>
    import('./components/AdminMaster/Gender');
const BloodGroup = () =>
    import('./components/AdminMaster/BloodGroup');
const Institute = () =>
    import('./components/AdminMaster/Institute');
const CompanyLevel = () =>
    import('./components/AdminMaster/CompanyLevel');
const Country = () =>
    import('./components/AdminMaster/Country');
const Language = () =>
    import('./components/AdminMaster/Language');
const TPOConfiguration = () =>
    import('./components/AdminMaster/TPOConfiguration');
const SkillCategory = () =>
    import('./components/AdminMaster/SkillCategory');
const SocialMediaType = () =>
    import('./components/AdminMaster/SocialMediaType');
const ClassGrade = () =>
    import('./components/AdminMaster/ClassGrade');
const Degree = () =>
    import('./components/AdminMaster/Degree');
const CommonNoticeMessage = () =>
    import('./components/AdminMaster/CommonNoticeMessage');
const CommonNoticeNew = () =>
    import('./components/AdminMaster/CommonNoticeNew');
const EmpTestType = () =>
    import('./components/AdminMaster/EmpTestType');
const PublicationLevel = () =>
    import('./components/AdminMaster/PublicationLevel');
const PublicationCategory = () =>
    import('./components/AdminMaster/PublicationCategory');
const InternshipType = () =>
    import('./components/AdminMaster/InternshipType');
const RecruitmemtType = () =>
    import('./components/AdminMaster/RecruitmemtType');
const PlacementType = () =>
    import('./components/AdminMaster/PlacementType');
const PlacementStatus = () =>
    import('./components/AdminMaster/PlacementStatus');
const CompanyType = () =>
    import('./components/AdminMaster/CompanyType');
const CompanyRound = () =>
    import('./components/AdminMaster/CompanyRound');
const FeedBackType = () =>
    import('./components/AdminMaster/FeedBackType');
const QuestionStatus = () =>
    import('./components/AdminMaster/QuestionStatus');
const ProjectCategory = () =>
    import('./components/AdminMaster/ProjectCategory');
const University = () =>
    import('./components/AdminMaster/University');
const WAT_Category_Mapping = () =>
    import('./components/AdminMaster/WAT_Category_Mapping');
const ActivityType = () =>
    import('./components/AdminMaster/ActivityType');
const YearMaster = () =>
    import('./components/AdminMaster/YearMaster');
const ReasonToBlockStudent = () =>
    import('./components/AdminMaster/ReasonToBlockStudent');
const FieldType = () =>
    import('./components/AdminMaster/FieldType');
const QuestionType = () =>
    import('./components/AdminMaster/QuestionType');
const CompanyOfferingQestion = () =>
    import('./components/AdminMaster/CompanyOfferingQestion');
const ProgramType = () =>
    import('./components/AdminMaster/ProgramType');
const ProgramTypeTPO = () =>
    import('./components/AdminMaster/ProgramTypeTPO');
const ProgramYear = () =>
    import('./components/AdminMaster/ProgramYear');
const UserType = () =>
    import('./components/AdminMaster/UserType');
const Role = () =>
    import('./components/AdminMaster/Role')
const TemplateType = () =>
    import('./components/AdminMaster/TemplateType');
const Department = () =>
    import('./components/AdminMaster/Department');
const WhatAppTemplateCategory = () =>
    import('./components/AdminMaster/WhatAppTemplateCategory');

const Year = () =>
    import('./components/AdminMaster/Year');
const YearTPO = () =>
    import('./components/AdminMaster/YearTPO');
const Skill = () =>
    import('./components/AdminMaster/Skill');
const Template = () =>
    import('./components/AdminMaster/Template');
const FieldConfig = () =>
    import('./components/AdminMaster/FieldConfig');
const RoleLevel = () =>
    import('./components/AdminMaster/RoleLevel');
const FieldMaster = () =>
    import('./components/AdminMaster/FieldMaster');
const AdminMaster = () =>
    import('./components/AdminMaster/AdminMaster');
const tpo_registration_policy = () =>
    import('./components/AdminMaster/tpo_registration_policy');
const PractceWebsite = () =>
    import('./components/AdminMaster/PractceWebsite');
const AdminProgram = () =>
    import('./components/AdminMaster/AdminProgram');
const AdminTPOProgram = () =>
    import('./components/AdminMaster/AdminTPOProgram');
const CommunicationMode = () =>
    import('./components/AdminMaster/CommunicationMode')
const ProfileTab = () =>
    import('./components/AdminMaster/ProfileTab')
//end .vrushali admin Master links.........................................................................
const RegistrationDates = () =>
    import('./components/AdminMaster/RegistrationDates');

// added by vrushali alumini/faculty links.........................................................................
const AddJobInternship = () =>
    import('./components/FacultyAlumniData/AddJobInternship')
//end vrushali alumini/faculty links links.........................................................................

//by vrushali add user,assign role to user ,add TPO
const User = () =>
    import('./components/Admin/AdminUser/User')
const AssignRoleToUser = () =>
    import('./components/Admin/AssignRoleToUser')
const AssignRoleGroupToUser = () =>
    import('./components/Admin/AssignRoleGroupToUser')
const AddTpo = () =>
    import('./components/Admin/AddTpo')
const UpdateStudentYear = () =>
    import('./components/Admin/UpdateStudentYear')
const RoleLinks = () =>
    import('./components/Admin/RoleLinks')
const UpdatePrn = () =>
    import('./components/Admin/UpdatePrn')
const PlacementYearConfig = () =>
    import('./components/Admin/PlacementYearConfig')
//ends assign add user,assign role to user ,add TPO

// <====Analytics_Dashboard===>
const Analytics_Dashboard = () =>
    import("./components/dashboard/Analytics_Dashboard")
const current_dashboard = () =>
    import("./components/dashboard/Current_dashboard")
const program_wise_dashboard = () =>
    import("./components/dashboard/program_wise_dashboard")
const skill_wise_dashboard = () =>
    import("./components/dashboard/skill_wise_dashboard")
const overall_dashboard = () =>
    import("./components/dashboard/overall_dashboard")
const location_dashboard = () =>
    import("./components/dashboard/location_dashboard")
const companywise_dashboard = () =>
    import("./components/dashboard/companywise_dashboard")


//by Vrushali Admin Import
const AdminImportMenu = () =>
    import('./components/AdminImport/AdminImportMenu')
const ImportAlumini = () =>
    import('./components/AdminImport/ImportAlumini')
const ImportDepartment = () =>
    import('./components/AdminImport/ImportDepartment')
const ImportEmployee = () =>
    import('./components/AdminImport/ImportEmployee')
const ImportFeedbackQuestionare = () =>
    import('./components/AdminImport/ImportFeedbackQuestionare')
const ImportProgram = () =>
    import('./components/AdminImport/ImportProgram')
const ImportStudent = () =>
    import('./components/AdminImport/ImportStudent')
const PlacedStudentImport = () =>
    import('./components/AdminImport/PlacedStudentImport')
const ImportTPOProgram = () =>
    import('./components/AdminImport/ImportTPOProgram')
const ImportYear = () =>
    import('./components/AdminImport/ImportYear')
const TPOFeedbackQuestions = () =>
    import('./components/TPO/TPOFeedbackQuestions')
const TPOOfferFeedbackQuestions = () =>
    import('./components/TPO/TPOOfferFeedbackQuestions')
const AddFeedbackOfferingQuestions = () =>
    import('./components/TPO/AddFeedbackOfferingQuestions')

//TPO links.....
const AddCompany = () =>
    import('./components/TPO/AddCompany')
const IndividualStudentReport = () =>
    import('./components/TPO/IndividualStudentReport')
const TPOCompanyScheduling = () =>
    import('./components/TPO/TPOCompanyScheduling')
const selectionProcedure = () =>
    import('./components/TPO/selectionProcedure')
const SendEmail = () =>
    import('./components/TPO/SendEmail')
const BranchwiseApplicants = () =>
    import('./components/TPO/BranchwiseApplicants')
const CompanyOnCampusProcess = () =>
    import('./components/TPO/CompanyOnCampusProcess')
const AppllicationReceivedList = () =>
    import('./components/TPO/ApplicationReceivedList')
const AppllicationReceivedWithComponent = () =>
    import('./components/TPO/AppllicationReceivedWithComponent')
const Notification = () =>
    import('./components/TPO/Notification')
const AddAttachment = () =>
    import('./components/TPO/AddAttachment')
const AddProgram = () =>
    import('./components/TPO/addcompanyprogram')
const CreateCriteria = () =>
    import('./components/TPO/CreateCriteria')
const PlacementInternshipReport = () =>
    import('./components/TPO/PlacementInternshipReport')
const OfferLetterReport = () =>
    import('./components/TPO/OfferLetterReport')
const TPO_Program_Program_Report = () =>
    import('./components/TPO/TPO_Program_Program_Report')
const PlacedStudentCountReport = () =>
    import('./components/TPO/PlacedStudentCountReport')
const TpoMasterMenu = () =>
    import('./components/TPO/TpoMasterMenu')
const AllReportMenu = () =>
    import('./components/TPO/AllReportMenu')
const StudentLogin = () =>
    import('./components/TPO/StudentLogin')
const UnfreezeDetailsPage = () =>
    import('./components/TPO/UnfreezeDetailsPage')
const BlockUnblockStudent = () =>
    import('./components/TPO/BlockUnblockStudent')
const EditStudentOffer = () =>
    import('./components/TPO/EditStudentOffer')
const BlockStudentForOffering = () =>
    import('./components/TPO/BlockStudentForOffering')
const CareerJet = () =>
    import('./components/TPO/CareerJet')
const FAQs = () =>
    import('./components/TPO/FAQs')
const CompaniesDashboard = () =>
    import('./components/LearnerData/CompaniesDashboard')
const CopyOfferingQuestions = () =>
    import('./components/TPO/CopyOfferingQuestions')
const OfferLetterReportNew = () =>
    import('./components/TPO/OfferLetterReportNew')

const OverAllReport = () => import('./components/TPO/OverAllReport')
const StudentCommomdata = () => import('./components/TPO/StudentCommomdata')
const placement_dashboard = () => import('./components/TPO/placement_dashboard')
const SynchWhatsappTemplate = () => import('./components/TPO/SynchWhatsappTemplate')
const college_placement_dashboard = () => import('./components/TPO/college_placement_dashboard')
const LearnerCollegePlacementDashboard = () => import('./components/TPO/LearnerCollegePlacementDashboard')
const Studentregistrationstatus = () => import('./components/TPO/Studentregistrationstatus')
const Studentregistrationdashboard = () => import('./components/TPO/Studentregistrationdashboard')
const StudentRegistrationListWithoutComponent = () => import('./components/TPO/StudentRegistrationListWithoutComponent')
const StudentRegistrationListWithComponent = () => import('./components/TPO/StudentRegistrationListWithComponent')
const ApplicationReport = () => import('./components/TPO/ApplicationReport')
const CompanyInformationReport = () => import('./components/TPO/CompanyInformationReport')
//Student Profile
const StudentProfile = () =>
    import('./components/LearnerData/StudentProfile')
const PersonalDetails = () =>
    import('./components/LearnerData/PersonalDetails')
const PersonalDetailsNew = () =>
    import('./components/LearnerData/PersonalDetailsNew')
const AddressDetailsDynamic = () =>
    import('./components/LearnerData/AddressDetailsDynamic')
const LearnerCVDetailsDynamic = () =>
    import('./components/LearnerData/LearnerCVDetailsDynamic')
const AcademicsDynamic = () =>
    import('./components/LearnerData/AcademicsDynamic')
const AddressDetals = () =>
    import('./components/LearnerData/AddressDetals')
const Academics = () =>
    import('./components/LearnerData/Academics')
const CurrentAcademics = () =>
    import('./components/LearnerData/CurrentAcademics')
const InternshipProject = () =>
    import('./components/LearnerData/InternshipProject')
const SkillCertification = () =>
    import('./components/LearnerData/SkillCertification')
const Experience = () =>
    import('./components/LearnerData/Experience')
const ExtraCurricular = () =>
    import('./components/LearnerData/ExtraCurricular')
const LearnerCVDetails = () =>
    import('./components/LearnerData/LearnerCVDetails')
const LearnerOfferLetter = () =>
    import('./components/LearnerData/LearnerOfferLetter')
const LearnerOfferLetterDynamic = () =>
    import('./components/LearnerData/LearnerOfferLetterDynamic')
const CurrentAcademicsDynamic = () =>
    import('./components/LearnerData/CurrentAcademicsDynamic')
const Publication = () =>
    import('./components/LearnerData/Publication')
const Social = () =>
    import('./components/LearnerData/Social')

//Student Links....
const PracticeWebsite = () =>
    import('./components/LearnerData/PracticeWebsite')
const TPORegistration = () =>
    import('./components/LearnerData/TPORegistration')
const LearnerHistory = () =>
    import('./components/LearnerData/LearnerHistory')
const StudentHistory = () =>
    import('./components/Student/StudentHistory')
const OffCampusJob = () =>
    import('./components/LearnerData/OffCampusJob')
const ScheduledCompanies = () =>
    import('./components/Student/ScheduledCompanies')
const ShowCompanies = () =>
    import('./components/Student/ShowCompanies')
const apply_company = () =>
    import('./components/Student/apply_company')
const TPOCompanyScheduling_new = () =>
    import('./components/TPO/TPOCompanyScheduling_new')
const AppliedCompany = () =>
    import('./components/Student/AppliedCompany')
const NotificationPage = () =>
    import('./components/Student/NotificationPage')
const ScheduleCompaniesNew = () =>
    import('./components/Student/ScheduleCompaniesNew')
const ApplicationTracking = () =>
    import('./components/Student/ApplicationTracking')
const QuestionBank = () =>
    import('./components/LearnerData/QuestionBank')
const AddQuestion = () =>
    import('./components/LearnerData/AddQuestion')
const ApproveQuestion = () =>
    import('./components/LearnerData/ApproveQuestion')
const CompanyInfo = () =>
    import('./components/Student/CompanyInfo')
const Employability = () =>
    import('./components/LearnerData/Employability')
const tporegistrationreport = () =>
    import('./components/TPO/tporegistrationreport')
const addprogrampresets = () =>
    import('./components/TPO/addprogrampresets')

export const routes = [
    //Experiments
    { path: '/fileupload', component: FileUpload },
    { path: '/shivam', component: shivam },
    { path: '/sample', component: Sample },
    { path: '/sample-calendar', component: SampleCalendar },
    { path: '/tt', component: TT },
    //ends

    //Admin
    {
        path: '/eps-admin',
        component: SignIn
    },

    //home
    {
        path: '/old_login',
        component: epsLogin
    },
    {
        path: '/',
        component: epsLogincopy
    },
    {
        path: '/alumini-login',
        component: epsLogin
    },
    {
        path: '/alumini-login',
        component: epsLogincopy
    },
    {
        path: '/Alumin-Signupcopy',
        component: AluminSignupcopy
    },
    {
        path: '/alumini-signup',
        component: alumin_signup
    },
    {
        path: '/forgot-passwordcopy',
        component: ForgotPasswordcopy
    },
    {
        path: '/change_password',
        name: 'change_password',
        component: ForgotPassword
    },

    {
        path: '/change-password',
        component: ChangePassword
    },
    {
        path: '/erp_check',
        name: 'erp_check',
        component: Erp_check
    },
    {
        path: '/user-profile',
        component: userProfile,
        beforeEnter: ((to, from, next) => {
            if (!localStorage.getItem('EPS-token')) next('/')
            else next()
        })
    },
    
    // {
    //     path: '/home',
    //     component: epsHome,
    //     beforeEnter: ((to, from, next) => {
    //         if (!localStorage.getItem('EPS-token')) next('/')
    //         else next()
    //     })
    // },
    {
        path: '/employer-feedback',
        name: 'employer-feedback',
        component: EmployerFeedback
    },
    // {
    //     path: '/erp_check',
    //     component: Erp_check,
    //     beforeEnter: ((to, from, next) => {
    //         if (!localStorage.getItem('EPS-token')) next('/')
    //         else next()
    //     })
    // },
    //TPO_SuperMaster....Onkar
    //Super Admin
    {
        path: '/eps-admin',
        name: 'eps-admin',
        component: LoginSuperAdmin,
    },
    {
        path: '/eps-admin-home',
        name: 'eps-admin-home',
        component: SuperAdminHome,
        children: [

            {
                path: '/admin-add-tenant',
                name: 'admin-add-tenant',
                component: AddTenant
            },
            {
                path: '/admin-supermasters',
                name: 'admin-supermasters',
                component: SuperMaster
            },
            {
                path: '/admin-add-organization',
                name: 'admin-add-organization',
                component: AddOrganization
            },
            {
                path: '/admin-link-super-master',
                name: 'admin-link-super-master',
                component: AdminLinkSuperMaster
            },
            {
                path: '/admin-job-api',
                name: 'admin-job-api',
                component: AdminJobAPI
            },
            {
                path: '/admin-crawler-site',
                name: 'admin-crawler-site',
                component: AdminCrawlerSite
            },
            {
                path: '/admin-add-user',
                name: 'admin-add-user',
                component: AdminAddUser
            },
            {
                path: '/push-masters-to-tenant',
                name: 'push-masters-to-tenant',
                component: PushMastersToTenant
            },

            {
                path: '/add-role-link',
                name: 'add-role-link',
                component: AddRoleLink
            },
            //Admin SuperMaster Onkar...
            //{path:'/SuperMaster',component:SuperMaster},
            // {

            // path : '/Admin/SuperMaster/AdminSuper',
            // component : AdminSuper
            //},
            {
                path: '/admin-acadamic-year-master',
                name: 'admin-academic-year-master',
                component: AdminAcademicYearMaster
            },

            // calendar year added by mukesh

            {
                path: '/admin-master-calendaryear',
                name: 'admin-master-calendaryear',
                component: AdminCalendarYearMaster
            },


            {
                path: '/admin-gender-master',
                name: 'admin-gender-master',
                component: AdminGenderMaster
            },
            {
                path: '/admin-user-type-master',
                name: 'admin-user-type-master',
                component: AdminUserTypeMaster
            },
            {
                path: '/admin-role-master',
                name: 'admin-role-master',
                component: AdminRoleMaster
            },
            {
                path: '/admin-industry-master',
                name: 'admin-industry-master',
                component: AdminIndustryMaster
            },
            {
                path: '/admin-country-master',
                name: 'admin-country-master',
                component: AdminCountryMaster
            },
            {
                path: '/admin-city-master',
                name: 'admin-city-master',
                component: AdminCityMaster
            },
            {
                path: '/admin-blood-group-master',
                name: 'admin-blood-group-master',
                component: AdminBloodGroupMaster
            },
            {
                path: '/admin-language-master',
                name: 'admin-language-master',
                component: AdminLanguageMaster
            },
            {
                path: '/admin-address-type-master',
                name: 'admin-address-type-master',
                component: AdminAddressTypeMaster
            },
            {
                path: '/admin-skill-category-master',
                name: 'admin-skill-category-master',
                component: AdminSkillCategoryMaster
            },
            {
                path: '/admin-skill-master',
                name: 'admin-skill-master',
                component: AdminSkillMaster
            },
            {
                path: '/admin-social-media-type-master',
                name: 'admin-social-media-type-master',
                component: AdminSocialMediaTypeMaster
            },
            {
                path: '/admin-degree-master',
                name: 'admin-degree-master',
                component: AdminDegreeMaster
            },

            {
                path: '/admin-class-grade-master',
                name: 'admin-class-grade-master',
                component: AdminClassGradeMaster
            },
            {
                path: '/admin-placement-status-master',
                name: 'admin-placement-status-master',
                component: AdminPlacementStatusMaster
            },
            {
                path: '/admin-employability-test-type-master',
                name: 'admin-employability-test-type-master',
                component: AdminEmployabilityTestTypeMaster
            },
            {
                path: '/admin-publication-level-master',
                name: 'admin-publication-level-master',
                component: AdminPublicationLevelMaster
            },
            {
                path: '/admin-publication-category-master',
                name: 'admin-publication-category-master',
                component: AdminPublicationCategoryMaster
            },
            {
                path: '/admin-recruitment-type-category-master',
                name: 'admin-recruitment-type-category-master',
                component: AdminRecruitmentTypeMaster
            },
            {
                path: '/admin-placement-type-master',
                name: 'admin-placement-type-master',
                component: AdminPlacementTypeMaster
            },
            {
                path: '/admin-company-type-master',
                name: 'admin-company-type-master',
                component: AdminCompanyTypeMaster
            },
            {
                path: '/admin-activity-type-master',
                name: 'admin-activity-type-master',
                component: AdminActivityTypeMaster
            },
            {
                path: '/admin-field-type-master',
                name: 'admin-field-type-master',
                component: AdminFieldTypeMaster
            },
            {
                path: '/admin-field-master',
                name: 'admin-field-master',
                component: AdminFieldMaster
            },
            {
                path: '/admin-template-type-master',
                name: 'admin-template-type-master',
                component: AdminTemplateTypeMaster
            },
            {
                path: '/admin-template-master',
                name: 'admin-template-master',
                component: AdminTemplateMaster
            },
            {
                path: '/admin-project-category-maste',
                name: 'admin-project-category-master',
                component: AdminProjectCategoryMaster
            },
            {
                path: '/admin-program-type-master',
                name: 'admin-program-type-master',
                component: AdminProgramTypeMaster
            },
            {
                path: '/admin-question-type-master',
                name: 'admin-question-type-master',
                component: AdminQuestionTypeMaster
            },
            {
                path: '/admin-question-status-master',
                name: 'admin-question-status-master',
                component: AdminQuestionStatusMaster
            },
            {
                path: '/admin-company-round-master',
                name: 'admin-company-round-master',
                component: AdminCompanyRoundMaster
            },
            {
                path: '/admin-communication-mode-master',
                name: 'admin-communication-mode-master',
                component: AdminCommunicationModeMaster
            },
            {

                path: '/admin-feedback-report',
                name: 'admin-feedback-report',
                component: FeedbackReport
            },
            {
                path: '/feedback-report-details',
                name: 'feedback-report-details',
                component: FeedbackReportDetails
            },
        ]
    },
    //--------------------------- Learner LearnerData added by vrushali ---------------------
    {
        path: '/home',
        name: 'home',
        component: epsHome,
        beforeEnter: ((to, from, next) => {
                    if (!localStorage.getItem('EPS-token')) next('/')
                    else next()
                }),
       
        children: [
            {
                path: '/update-password',
                component: updatePassword,
                beforeEnter: ((to, from, next) => {
                    if (!localStorage.getItem('EPS-token')) next('/')
                    else next()
                })
            },
            //Student Profile  New Link
            {
                path: '/student-questionbank',
                name: 'student-questionbank',
                component: QuestionBank
            },
            {
                path: '/student-addquestion',
                name: 'student-addquestion',
                component: AddQuestion
            },
            {
                path: '/student-approvequestion',
                name: 'student-approvequestion',
                component: ApproveQuestion
            },
            {
                path: '/student-profile',
                name: 'student-profile',
                component: StudentProfile
            },
            {
                path: '/scheduled-companies',
                name: 'scheduled-companies',
                component: ScheduledCompanies
            },
            {
                path: '/show-companies',
                name: 'show-companies',
                component: ShowCompanies
            },
            {
                path: '/apply_company',
                name: 'apply_company',
                component: apply_company
            },
            {
                path: '/TPOCompanyScheduling_new',
                name: 'TPOCompanyScheduling_new',
                component: TPOCompanyScheduling_new
            },
            {
                path: '/applied-company',
                name: 'applied-company',
                component: AppliedCompany
            },
            {
                path: '/notification-page',
                name: 'notification-page',
                component: NotificationPage
            },
            {
                path: '/scheduled-companies-new',
                name: 'scheduled-companies-new',
                component: ScheduleCompaniesNew
            },
            {
                path: '/application-tracking',
                name: 'application-tracking',
                component: ApplicationTracking
            },
            {
                path: '/student-academics',
                name: 'student-academics',
                component: Academics
            },
            {
                path: '/student-Current-academics',
                name: 'student-Current-academics',
                component: CurrentAcademics
            },
            {
                path: '/student-personalDetails',
                name: 'student-personalDetails',
                component: PersonalDetails
            },
            {
                path: '/student-personalDetailsNew',
                name: 'student-personalDetailsNew',
                component: PersonalDetailsNew
            },
            {
                path: '/student-addressDetailsDynamic',
                name: 'student-addressDetailsDynamic',
                component: AddressDetailsDynamic
            },
            {
                path: '/student-cvdynamic',
                name: 'student-cvdynamic',
                component: LearnerCVDetailsDynamic
            },
            {
                path: '/student-offerletterdynamic',
                name: 'student-offerletterdynamic',
                component: LearnerOfferLetterDynamic
            },
            {
                path: '/student-Current-academics-dynamic',
                name: 'student-Current-academics-dynamic',
                component: CurrentAcademicsDynamic
            },
            {
                path: '/student-academicsdynamic',
                name: 'student-academicsdynamic',
                component: AcademicsDynamic
            },
            {
                path: '/student-addressDetals',
                name: 'student-addressDetals',
                component: AddressDetals
            },
            {
                path: '/student-internship-project',
                name: 'student-internship-project',
                component: InternshipProject
            },
            {
                path: '/student-skill-certification',
                name: 'student-skill-certification',
                component: SkillCertification
            },
            {
                path: '/student-experience',
                name: 'student-experience',
                component: Experience
            },
            {
                path: '/student-extra-curricular',
                name: 'student-extra-curricular',
                component: ExtraCurricular
            },
            {
                path: '/student-cv',
                name: 'student-cv',
                component: LearnerCVDetails
            },
            {
                path: '/student-offerletter',
                name: 'student-offerletter',
                component: LearnerOfferLetter
            },

            {
                path: '/student-publication',
                name: 'student-publication',
                component: Publication
            },
            {
                path: '/student-social',
                name: 'student-social',
                component: Social
            },
            {
                path: '/employability',
                name: 'employability',
                component: Employability
            },

            //Learner Login Links

            {
                path: '/student-practice-website',
                name: 'student-practice-website',
                component: PracticeWebsite
            },
            {
                path: '/student-tpo-registration',
                name: 'student-tpo-registration',
                component: TPORegistration
            },
            {
                path: '/student-history',
                name: 'student-history',
                component: StudentHistory
            },
            {
                path: '/learner-history',
                name: 'learner-history',
                component: LearnerHistory
            },
            {
                path: '/student-offcampus-job',
                name: 'student-offcampus-job',
                component: OffCampusJob
            },

            ///admin master------------added by vrushali
            { path: '/Master', component: Master },
            {
                path: '/admin-master-acdemic-year',
                name: 'admin-master-acdemic-year',
                component: AcademicYear
            },
           
            {
                path: '/erp_check',
                name: 'erp_check',
                component: Erp_check
            },
            {
                path: '/admin-master-learnercvtype',
                name: 'admin-master-learnercvtype',
                component: CVType
            },
            {
                path: '/admin-master-learnercvtemplate',
                name: 'admin-master-learnercvtemplate',
                component: CVTemplate
            },
            {
                path: '/admin-master-semester',
                name: 'admin-master-semester',
                component: Semester
            },
            {
                path: '/admin-master-setting',
                name: 'admin-master-setting',
                component: Setting
            },
            {
                path: '/common-notice-new',
                name: 'common-notice-new',
                component: CommonNoticeNew
            },
            {
                path: '/admin-master-industry',
                name: 'admin-master-industry',
                component: Industry
            },
            {
                path: '/admin-master-city',
                name: 'admin-master-city',
                component: City
            },
            {
                path: '/admin-master-addressType',
                name: 'admin-master-addressType',
                component: AddressType
            },
            {
                path: '/admin-master-gender',
                name: 'admin-master-gender',
                component: Gender
            },
            {
                path: '/admin-master-bloodgroup',
                name: 'admin-master-bloodgroup',
                component: BloodGroup
            },
            {
                path: '/admin-master-institute',
                name: 'admin-master-institute',
                component: Institute
            },
            {
                path: '/admin-master-companylevel',
                name: 'admin-master-companylevel',
                component: CompanyLevel
            },
            {
                path: '/admin-master-rolelevel',
                name: 'admin-master-rolelevel',
                component: RoleLevel
            },
            {
                path: '/admin-master-country',
                name: 'admin-master-country',
                component: Country
            },
            {
                path: '/admin-master-language',
                name: 'admin-master-language',
                component: Language
            },
            {
                path: '/admin-master-tpoconfiguration',
                name: 'admin-master-tpoconfiguration',
                component: TPOConfiguration
            },
            {
                path: '/admin-master-skillcategory',
                name: 'admin-master-skillcategory',
                component: SkillCategory
            },
            {
                path: '/admin-master-socialmediatype',
                name: 'admin-master-socialmediatype',
                component: SocialMediaType
            },
            {
                path: '/admin-master-classgrade',
                name: 'admin-master-classgrade',
                component: ClassGrade
            },
            {
                path: '/admin-master-degree',
                name: 'admin-master-degree',
                component: Degree
            },
            {
                path: '/common-notice-messege',
                name: 'common-notice-messege',
                component: CommonNoticeMessage
            },
            {
                path: '/admin-master-emptesttype',
                name: 'admin-master-emptesttype',
                component: EmpTestType
            },
            {
                path: '/admin-master-publicationlevel',
                name: 'admin-master-publicationlevel',
                component: PublicationLevel
            },
            {
                path: '/admin-master-publicationcategory',
                name: 'admin-master-publicationcategory',
                component: PublicationCategory
            },
            {
                path: '/admin-master-internshiptype',
                name: 'admin-master-internshiptype',
                component: InternshipType
            },
            {
                path: '/admin-master-recruitmemttype',
                name: 'admin-master-recruitmemttype',
                component: RecruitmemtType
            },
            {
                path: 'admin-master-placementtype',
                name: 'admin-master-placementtype',
                component: PlacementType
            },
            {
                path: '/admin-master-placementstatus',
                name: 'admin-master-placementstatus',
                component: PlacementStatus
            },
            {
                path: '/admin-master-companytype',
                name: 'admin-master-companytype',
                component: CompanyType
            },
            {
                path: '/admin-master-company-round',
                name: 'admin-master-company-round',
                component: CompanyRound
            },
            {
                path: '/admin-master-feedbacktype',
                name: 'admin-master-feedbacktype',
                component: FeedBackType
            },
            {
                path: '/admin-master-question-status',
                name: 'admin-master-question-status',
                component: QuestionStatus
            },
            {
                path: '/admin-master-project-category',
                name: 'admin-master-project-category',
                component: ProjectCategory
            },
            {
                path: '/admin-master-activitytype',
                name: 'admin-master-activitytype',
                component: ActivityType
            },
            {
                path: '/admin-master-University',
                name: 'admin-master-University',
                component: University
            },
            {
                path: '/admin-master-programyear',
                name: 'admin-master-programyear',
                component: ProgramYear
            },
            {
                path: '/admin-master-WAT_Category_Mapping',
                name: 'admin-master-WAT_Category_Mapping',
                component: WAT_Category_Mapping
            },
            {
                path: '/admin-master-yearmaster',
                name: 'admin-master-yearmaster',
                component: YearMaster
            },
            {
                path: '/admin-master-reasontoblockstudent',
                name: 'admin-master-reasontoblockstudent',
                component: ReasonToBlockStudent
            },
            {
                path: '/company-offering-round-learner-status',
                name: 'company-offering-round-learner-status',
                component: CompanyOfferingRoundLearnerStatus
            },
            {
                path: '/admin-master-field-type',
                name: 'admin-master-field-type',
                component: FieldType
            },
            {
                path: '/admin-master-questiontype',
                name: 'admin-master-questiontype',
                component: QuestionType
            },
            {
                path: '/admin-master-offeringquestion',
                name: 'admin-master-offeringquestion',
                component: CompanyOfferingQestion
            },
            {
                path: '/admin-master-programtype',
                name: 'admin-master-programtype',
                component: ProgramType
            },
            {
                path: '/tpo-master-programtype',
                name: 'tpo-master-programtype',
                component: ProgramTypeTPO
            },
            {
                path: '/admin-master-usertype',
                name: 'admin-master-usertype',
                component: UserType
            },
            {
                path: '/admin-master-role',
                name: 'admin-master-role',
                component: Role
            },
            {
                path: '/admin-master-template-type',
                name: 'admin-master-template-type',
                component: TemplateType
            },
            {
                path: '/admin-department',
                name: 'admin-department',
                component: Department
            },
            {
                path: '/admin-whatsappcategeory',
                name: 'admin-whatsappcategeory',
                component: WhatAppTemplateCategory
            },
            {
                path: '/admin-master-year',
                name: 'admin-master-year',
                component: Year
            },
            {
                path: '/tpo-master-year',
                name: 'tpo-master-year',
                component: YearTPO
            },
            {
                path: '/admin-master-skill',
                name: 'admin-master-skill',
                component: Skill
            },
            {
                path: '/admin-master-template',
                name: 'admin-master-template',
                component: Template
            },
            {
                path: '/admin-field-config',
                name: 'admin-field-config',
                component: FieldConfig
            },
            {
                path: '/admin-fieldmaster',
                name: 'admin-fieldmaster',
                component: FieldMaster
            },
            {
                path: '/admin-master',
                name: 'admin-master',
                component: AdminMaster
            },
            {
                path: '/tpo_registration_policy',
                name: 'tpo_registration_policy',
                component: tpo_registration_policy
            },
            {
                path: '/admin-import',
                name: 'admin-import',
                component: AdminImportMenu
            },
            {
                path: '/admin-master-program',
                name: 'admin-master-program',
                component: AdminProgram
            },
            {
                path: '/admin-master-tpoprogram',
                name: 'admin-master-tpoprogram',
                component: AdminTPOProgram
            },
            {
                path: '/admin-master-communication-mode',
                name: 'admin-master-communication-mode',
                component: CommunicationMode
            },
            {
                path: '/profile_tab',
                name: 'profile_tab',
                component: ProfileTab
            },


            //end of admin master------------added by vrushali

            {
                path: '/admin-practice-website',
                name: 'admin-practice-website',
                component: PractceWebsite
            },

            {
                path: '/admin-user',
                name: 'admin-user',
                component: User
            },
            //by vrushali assign role to user
            {
                path: '/assign-role-to-user',
                name: 'assign-role-to-user',
                component: AssignRoleToUser
            },
            {
                path: '/assign-rolegroup-to-user',
                name: 'assign-rolegroup-to-user',
                component: AssignRoleGroupToUser
            },
            {
                path: '/admin-add-tpo',
                name: 'admin-add-tpo',
                component: AddTpo
            },
            {
                path: '/update-year',
                name: 'update-year',
                component: UpdateStudentYear
            },
            {
                path: '/admin-add-rolelink',
                name: 'admin-add-rolelink',
                component: RoleLinks
            },
            {
                path: '/admin-updateprn',
                name: 'admin-updateprn',
                component: UpdatePrn
            },
            {
                path: '/admin-import-alumni',
                name: 'admin-import-alumni',
                component: ImportAlumini
            },
            {
                path: '/admin-import-department',
                name: 'admin-import-department',
                component: ImportDepartment
            },
            {
                path: '/admin-import-employee',
                name: 'admin-import-employee',
                component: ImportEmployee
            },
            {
                path: '/admin-import-feedbackquestionare',
                name: 'admin-import-feedbackquestionare',
                component: ImportFeedbackQuestionare
            },
            {
                path: '/admin-import-program',
                name: 'admin-import-program',
                component: ImportProgram
            },
            {
                path: '/admin-import-student',
                name: 'admin-import-student',
                component: ImportStudent
            },
            {
                path: '/placed-student-import',
                name: 'placed-student-import',
                component: PlacedStudentImport
            },
            {
                path: '/admin-import-tpoprogram',
                name: 'admin-import-tpoprogram',
                component: ImportTPOProgram
            },
            {
                path: '/admin-import-year',
                name: 'admin-import-year',
                component: ImportYear
            },
            {
                path: '/admin-placement-year-config',
                name: 'admin-placement-year-config',
                component: PlacementYearConfig
            },
            // <====Analytics Dashboard====>
            {
                path: "/analytics-dashboard",
                name: "analytics-dashboard",
                component: Analytics_Dashboard
            },
            {
                path: "/current_dashboard",
                name: "current_dashboard",
                component: current_dashboard
            },
            {
                path: "/program_wise_dashboard",
                name: "program_wise_dashboard",
                component: program_wise_dashboard
            },
            {
                path: "/skill_wise_dashboard",
                name: "skill_wise_dashboard",
                component: skill_wise_dashboard
            },
            {
                path: "/overall_dashboard",
                name: "overall_dashboard",
                component: overall_dashboard
            },

            {
                path: "/location_dashboard",
                name: "location_dashboard",
                component: location_dashboard
            },
            {
                path: "/companywise_dashboard",
                name: "companywise_dashboard",
                component: companywise_dashboard
            },


            //almini/faculty login link added by vrushali 
            {
                path: '/add-job-internship',
                name: 'add-job-internship',
                component: AddJobInternship
            },


            //end ofalmini/faculty login link added by vrushali


            //TPO Login Links 
            //almini/faculty login link added by vrushali 
            {
                path: '/add-company',
                name: 'add-company',
                component: AddCompany
            },
            {
                path: '/admin-master-regdates',
                name: 'admin-master-regdates',
                component: RegistrationDates
            },
            {
                path: '/tpo-company-scheduling',
                name: 'tpo-company-scheduling',
                component: TPOCompanyScheduling
            }, //
            {
                path: '/tpo-company-campus-process',
                name: 'tpo-company-campus-process',
                component: CompanyOnCampusProcess
            },
            {
                path: '/application-received-with-component',
                name: 'application-received-with-component',
                component: AppllicationReceivedWithComponent
            },
            {
                path: '/application-received',
                name: 'application-received',
                component: AppllicationReceivedList
            },
            {
                path: '/tpo-notification',
                name: 'tpo-notification',
                component: Notification
            },
            {
                path: '/selection-procedure',
                name: 'selection-procedure',
                component: selectionProcedure
            },
            {
                path: '/send-email',
                name: 'send-email',
                component: SendEmail
            },
            {
                path: '/branchwise-applicants',
                name: 'branchwise-applicants',
                component: BranchwiseApplicants
            },
            {
                path: '/tpo-add-attachment',
                name: 'tpo-add-attachment',
                component: AddAttachment
            },
            {
                path: '/tpo-add-program',
                name: 'tpo-add-program',
                component: AddProgram
            },
            {
                path: '/tpo-create-criteria',
                name: 'tpo-create-criteria',
                component: CreateCriteria
            },
            {
                path: '/placement-report',
                name: 'placement-report',
                component: PlacementInternshipReport
            },
            {
                path: '/offerletter-report',
                name: 'offerletter-report',
                component: OfferLetterReport
            },
            {
                path: '/tpo_program_program_report',
                name: 'tpo_program_program_report',
                component: TPO_Program_Program_Report
            },
            {
                path: '/placedcount-report',
                name: 'placedcount-report',
                component: PlacedStudentCountReport
            },
            {
                path: '/company-info',
                name: 'company-info',
                component: CompanyInfo
            },
            {
                path: '/tpo-masters',
                name: 'tpo-masters',
                component: TpoMasterMenu
            },
            {
                path: '/all-report-menu',
                name: 'all-report-menu',
                component: AllReportMenu
            },
            {
                path: '/tporegistrationreport',
                name: 'tporegistrationreport',
                component: tporegistrationreport
            },
            {
                path: '/placement-overallreport',
                name: 'placement-overallreport',
                component: OverAllReport
            },
            {
                path: '/student-commondata',
                name: 'student-commondata',
                component: StudentCommomdata
            },
            {
                path: '/placement-dashboard',
                name: 'placement-dashboard',
                component: placement_dashboard
            },
            {
                path: '/synch-whatsapp-template',
                name: 'synch-whatsapp-template',
                component: SynchWhatsappTemplate
            },
            {
                path: '/college-placement-dashboard',
                name: 'college-placement-dashboard',
                component: college_placement_dashboard
            },
            {
                path: '/learner-college-placement-dashboard',
                name: 'LearnerCollegePlacementDashboard',
                component: LearnerCollegePlacementDashboard
            },
            {
                path: '/student_registration_status',
                name: 'Studentregistrationstatus',
                component: Studentregistrationstatus
            },
            {
                path: '/student_registration_dashboard',
                name: 'Studentregistrationdashboard',
                component: Studentregistrationdashboard
            },
            {
                path: '/application-report',
                name: 'applicationReport',
                component: ApplicationReport
            },
            {
                path: '/company-info-report',
                name: 'companyinformationReport',
                component: CompanyInformationReport
            },
            {
                path: '/IndividualStudentReport',
                name: 'IndividualStudentReport',
                component: IndividualStudentReport
            },
            {
                path: '/student-registration-list-without-component',
                name: 'StudentRegistrationListWithoutComponent',
                component: StudentRegistrationListWithoutComponent

            },
            {
                path: '/student-registration-list-with-component',
                name: 'StudentRegistrationListWithComponent',
                component: StudentRegistrationListWithComponent

            },
            {
                path: '/student-login',
                name: 'student-login',
                component: StudentLogin
            },
            {
                path: '/unfreez-details',
                name: 'unfreez-details',
                component: UnfreezeDetailsPage
            },
            {
                path: '/block-unblock-student',
                name: 'block-unblock-student',
                component: BlockUnblockStudent
            },
            {
                path: '/edit-student-offer',
                name: 'edit-student-offer',
                component: EditStudentOffer
            },
            {
                path: '/block-student-for-offering',
                name: 'block-student-for-offering',
                component: BlockStudentForOffering
            },
            {
                path: '/careerjet-page',
                name: 'careerjet-page',
                component: CareerJet
            },
            {
                path: '/faq-page',
                name: 'faq-page',
                component: FAQs
            },
            {
                path: '/company-dashboard',
                name: 'company-dashboard',
                component: CompaniesDashboard
            },
            {
                path: '/copy-offering-questions',
                name: 'copy-offering-questions',
                component: CopyOfferingQuestions
            },
            {
                path: '/add-feedback-questions',
                name: 'add-feedback-questions',
                component: TPOFeedbackQuestions
            },
            {
                path: '/offer-feedback-questions',
                name: 'offer-feedback-questions',
                component: TPOOfferFeedbackQuestions
            },
            {
                path: '/add-feedback-offering-questions',
                name: 'add-feedback-offering-questions',
                component: AddFeedbackOfferingQuestions
            },
            {
                path: '/offerletterreportnew',
                name: 'offerletterreportnew',
                component: OfferLetterReportNew
            },
            {
                path: '/addprogrampresets',
                name: 'addprogrampresets',
                component: addprogrampresets
            },

        ]
    },
];